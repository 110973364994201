import React, {createContext, useReducer} from "react";
import {PlayerReducer} from "../reducer/PlayerReducer";
import {HelpTypes} from "../PlayerConstant";

export const PlayerContext = createContext();

const PlayerContextProvider = (props) => {
    const initState = {
        isInitialized: true,
        helpType: HelpTypes.DEFAULT,
        scope: undefined,
        cartScanProgress: undefined,
        isBackSelect: false,
        stagingStatus: undefined
    };

    const [statePlayer, dispatchPlayer] = useReducer(PlayerReducer, initState);

    return (
        <PlayerContext.Provider value={{statePlayer, dispatchPlayer}}>
            {props.children}
        </PlayerContext.Provider>
    )
}

export default PlayerContextProvider;