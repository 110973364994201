import React, {useEffect, useState} from 'react';
import LocaleManagerInstance from "./il8n/handler/locale-manager";
import AppContextProvider from "./context/app-context";
import PickListDetailContextProvider from "./context/picklist-detail-context";
import Column from "@amzn/meridian/column";
import AppTopNavigation from "./components/app-top-navigation";
import Routing from "./routing";
import {IntlProvider} from "react-intl";
import useDevSupport from "./dev-support";
import {Loader, TrainingBlockerView} from "@amzn/dolphin-uiweb-framework";
import {DataHandler, FeatureManager, MeshClient, NativeMeshInteractor, PlayerHelper} from "@amzn/dolphin-web-framework";
import AppDataStore, {APP_DATA_KEYS} from "./components/app-data";
import PlayerContextProvider from "./player/context/PlayerContext";
import {PlayerConstants} from "./player/PlayerConstant";
import {getRegion, getEnv} from "./bridge/utils"
import {STRINGS} from "./il8n/strings";
import {Modules} from "./constants/Modules";
import {ItemType} from "./models/enums/item-enums";

function App() {
    const [loadFeatures, setLoadFeatures] = useState(true);
    const [trainingBlocker, setTrainingBlocker] = useState(false);
    useEffect( () => {
        async function initialize() {
            await MeshClient.connectWebSocket();
            let redirectData = DataHandler.getRedirectData();
            const isRedirectFromPick = redirectData && (redirectData.redirectFromModule === Modules.PICK);

            await FeatureManager.getFeatures(AppDataStore.get(APP_DATA_KEYS.APP_ENV),
                AppDataStore.get(APP_DATA_KEYS.APP_REGION)).then(()=>{
                setLoadFeatures(false);
            });

            if (isRedirectFromPick) {
                let lastPickedItemType = redirectData.hasOwnProperty('lastPickedItemType') ? redirectData.lastPickedItemType : ItemType.BAG;

                if (lastPickedItemType === ItemType.PACKAGE) {
                    PlayerHelper.sendMessage(new window.DolphinPlayer.Pick.PackageScanOptionSelectedEvent(window.DolphinPlayer.Pick.PackageScanOption.CART_FULL),
                        new window.DolphinPlayer.Pick.LocationScanState(false));
                } else {
                    PlayerHelper.sendMessage(new window.DolphinPlayer.Pick.BagScanOptionSelectedEvent(window.DolphinPlayer.Pick.BagScanOption.CART_FULL),
                        new window.DolphinPlayer.Pick.LocationScanState(false));
                }
            } else {
                PlayerHelper.init(PlayerConstants.PROCESS_PATH, PlayerConstants.VERSION, getEnv(), getRegion())
                    .then(() => {
                        PlayerHelper.sendMessage(new window.DolphinPlayer.DrsStage.ProcessSelectedEvent(), new window.DolphinPlayer.DrsStage.TaskSelectState());
                    });
            }
            setTrainingBlocker(false);
        }

        initialize();
        return () => {
            MeshClient.closeWebSocketConnection();
            PlayerHelper.terminate();
        };
    },[]);

    const renderTrainingBlockerView = () => {
        return <TrainingBlockerView
            title={STRINGS.TRAINING_BLOCKER_TITLE}
            description={STRINGS.TRAINING_BLOCKER_DESC}
            buttonText={STRINGS.GOT_IT}
            buttonOnClick={() => NativeMeshInteractor.exitModule()}/>
    }

    return (
        <IntlProvider locale={LocaleManagerInstance.getLocale()} messages={LocaleManagerInstance.getMessages()}>
            <PlayerContextProvider>
            <AppContextProvider>
                <PickListDetailContextProvider>
                    {useDevSupport(
                        trainingBlocker
                            ? renderTrainingBlockerView()
                            : <Column height="100%" spacing="none">
                                <AppTopNavigation/>
                                {loadFeatures ? <Loader/> :
                                <Column id={"container"} className="container" width="100%" height="100%"
                                        spacingInset="none">
                                    <Routing/>
                                </Column>}
                            </Column>
                    )}
                </PickListDetailContextProvider>
            </AppContextProvider>
            </PlayerContextProvider>
        </IntlProvider>
    );
}

export default App;
