import AppDataStore, {APP_DATA_KEYS} from "../components/app-data";

export const picklistDetailActions = {
    SET_ACTIVE_CART : "SET_ACTIVE_CART",
    SET_PICKLIST_ID : "SET_PICKLIST_ID",
    SET_ROUTE_ID : "SET_ROUTE_ID",
    SET_ROUTE_CODE : "SET_ROUTE_CODE",
    SET_PICKING_AISLE : "SET_PICKING_AISLE",
    SET_ACTIVE_STAGE : "SET_ACTIVE_STAGE",
    SET_CHUTES : "SET_CHUTES",
    SET_ROUTE_LENGTH : "SET_ROUTE_LENGTH",
    SET_PROGRAM_TYPE : "SET_PROGRAM_TYPE",
    SET_PICK_STATUS : "SET_PICK_STATUS",
    SET_EXECUTION_MODE : "SET_EXECUTION_MODE",
    SET_PICK_LIST_INFO : "SET_PICK_LIST_INFO",
    SET_PICK_LIST_DETAIL : "SET_PICK_LIST_DETAIL",
    SET_STATUS : "SET_STATUS",
    SET_CURRENT_CART : "SET_CURRENT_CART",
    SET_CONTAINER_TASK : "SET_CONTAINER_TASK",
    SET_ROUTE_NODE_ID : "SET_ROUTE_NODE_ID",
    SET_RESOURCE_NODE : "SET_RESOURCE_NODE",
    SET_PICKLIST_INDEX : "SET_PICKLIST_INDEX",
    SET_SCANNED_CONTENT: "SET_SCANNED_CONTENT",
    SET_LAST_RESOLUTION: "SET_LAST_RESOLUTION"
}

export const picklistDetailReducer = (state, action) => {
    switch (action.type) {
        case picklistDetailActions.SET_ACTIVE_CART :
            return {...state, activeCart : action.value}
        case picklistDetailActions.SET_PICKLIST_ID :
            return {...state, picklistId : action.value}
        case picklistDetailActions.SET_ROUTE_ID :
            return {...state, routeId : action.value}
        case picklistDetailActions.SET_ROUTE_CODE :
            return {...state, routeCode : action.value}
        case picklistDetailActions.SET_PICKING_AISLE :
            return {...state, pickingAisle : action.value}
        case picklistDetailActions.SET_ACTIVE_STAGE :
            return {...state, activeStage : action.value}
        case picklistDetailActions.SET_CHUTES :
            return {...state, chutes : action.value}
        case picklistDetailActions.SET_ROUTE_LENGTH :
            return {...state, routeLength : action.value}
        case picklistDetailActions.SET_PROGRAM_TYPE :
            return {...state, programType : action.value}
        case picklistDetailActions.SET_PICK_STATUS :
            return {...state, pickStatus : action.value}
        case picklistDetailActions.SET_EXECUTION_MODE :
            return {...state, executionMode : action.value}
        case picklistDetailActions.SET_PICK_LIST_INFO :
            return {...state, picklistInfo : action.value}
        case picklistDetailActions.SET_PICK_LIST_DETAIL :
            return {...state, picklistDetail : action.value}
        case picklistDetailActions.SET_CURRENT_CART:
            AppDataStore.save(APP_DATA_KEYS.CURRENT_CART, action.value);
            return {...state, currentCart : action.value}
        case picklistDetailActions.SET_CONTAINER_TASK :
            return {...state, containerTask : action.value}
        case picklistDetailActions.SET_ROUTE_NODE_ID :
            return {...state, routeNodeId : action.value}
        case picklistDetailActions.SET_RESOURCE_NODE :
            return {...state, resourceNodeId : action.value}
        case picklistDetailActions.SET_PICKLIST_INDEX :
            return {...state, picklistIndex : action.value}
        case picklistDetailActions.SET_LAST_RESOLUTION :
            return {...state, lastResolution : action.value}
        case picklistDetailActions.SET_SCANNED_CONTENT :
            return {...state, scannedContent : action.value}
        default:
            return state;
    }
}