import Column from "@amzn/meridian/column";
import React from "react";
import {Loader} from "@amzn/dolphin-uiweb-framework";

export default function AppLoader(props) {
    return (
        <Column className="appLoader" heights="fill" alignmentHorizontal="center" spacingInset="none">
            <Loader/>
        </Column>
    );
}