export const ApplicationActions = {
    TASK_SELECTION: "TASK_SELECTION",
    ENTER_ROUTE_CODE: "ENTER_ROUTE_CODE",
    OPEN_HELP_OPTION: "OPEN_HELP_OPTION",
    CART_REPLENISHMENT: "CART_REPLENISHMENT",
    CART_SCAN: "CART_SCAN",
    HELP_CENTRE_OPTION_SELECTED: "HELP_CENTRE_OPTION_SELECTED",
    LOCATION_SCAN: "LOCATION_SCAN",
    STAGING_COMPLETE_OPTION_SELECTED: "STAGING_COMPLETE_OPTION_SELECTED",
    UPDATE_STATE: "UPDATE_STATE",
    ON_BACK_SELECT: "ON_BACK_SELECT",
    REACHED_CART_LOCATION_OPTION_SELECTED: "REACHED_CART_LOCATION_OPTION_SELECTED",
    ON_EXIT_MODULE: "ON_EXIT_MODULE",
    ON_MISSING_CART_CANCEL_OPTION: "ON_MISSING_CART_CANCEL_OPTION",
    ON_BACK_DROP_OPEN: "ON_BACK_DROP_OPEN",
    STAGING_LOCATION_FULL_CONFIRM: "STAGING_LOCATION_FULL_CONFIRM"
};


export const Operations = {
    STAGE_ROUTE: "STAGE_ROUTE",
    ROUTE_CODE_ENTRY: "ROUTE_CODE_ENTRY",
    VALID_ROUTE_CODE_ENTERED: "VALID_ROUTE_CODE_ENTERED",
    VALID_CART_SCAN: "VALID_CART_SCAN",
    INCORRECT_CART_SCAN: "INCORRECT_CART_SCAN",
    CART_ALREADY_STAGED: "CART_ALREADY_STAGED",
    MISSING_CART: "MISSING_CART",
    STAGING_IN_PROGRESS: "STAGING_IN_PROGRESS",
    INVALID_LOCATION_SCAN: "INVALID_LOCATION_SCAN",
    VALID_LOCATION_SCAN: "VALID_LOCATION_SCAN",
    STAGING_STATUS_COMPLETE: "STAGING_STATUS_COMPLETE",
    STAGING_STATUS_PARTIALLY_COMPLETE: "STAGING_STATUS_PARTIALLY_COMPLETE",
    NO_CART_STAGED: "NO_CART_STAGED",
    STAGE_ANOTHER_ROUTE: "STAGE_ANOTHER_ROUTE",
    END_STAGING: "END_STAGING",
    UPDATE_SCOPE: "UPDATE_SCOPE",
    UPDATE_SCOPE_AMZL: "UPDATE_SCOPE_AMZL",
    UPDATE_CART_SCAN_PROGRESS: "UPDATE_CART_SCAN_PROGRESS",
    UPDATE_BACK_SELECT_STATE: "UPDATE_BACK_SELECT_STATE",
    ACTION_ACKNOWLEDGED: "ACTION_ACKNOWLEDGED",
    ACTION_DECLINED: "ACTION_DECLINED",
    STAGING_FULL: "STAGING_FULL"
}