import syncTokens from "@amzn/meridian-tokens/base/icon/sync";
import undoTokens from "@amzn/meridian-tokens/base/icon/undo";
import refreshTokens from "@amzn/meridian-tokens/base/icon/refresh";
import expandTokens from "@amzn/meridian-tokens/base/icon/expand";
import cameraTokens from "@amzn/meridian-tokens/base/icon/camera";
import userTokens from "@amzn/meridian-tokens/base/icon/user";
import React from 'react';
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import Icon from "@amzn/meridian/icon";
import Box from "@amzn/meridian/box";
import Input from "@amzn/meridian/input";

import './dev-support.css';
import Alert from "@amzn/meridian/alert";
import Toaster from "@amzn/meridian/toaster";
import Checkbox from "@amzn/meridian/checkbox";
import AppDataStore, {APP_DATA_KEYS} from "./components/app-data";
import Select, {SelectOption} from "@amzn/meridian/select";
import Text from "@amzn/meridian/text";
import Divider from "@amzn/meridian/divider";
import {DataHandler, FeatureManager} from "@amzn/dolphin-web-framework";
import {sendScanEventForText} from "./bridge/scan-input";

const TOASTER_TIMEOUT = 1000;
const ENVS = ["beta", "gamma", "prod"];
const REGIONS = ["NA", "EU", "FE", "IN"];
const LOCALE_MAP = {
    "en": "English",
    "de": "German",
    "es": "Spanish",
    "fr": "French",
    "hi": "Hindi",
    "it": "Italian",
    "ja": "Japanese",
    "pt": "Portuguese",
    "ro": "Romanian"
};

/* returns true for mobile devices*/
export function isMobile() {
    let check = false;
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
}

/* View in fullscreen */
function openFullscreen() {
    if (document.body.requestFullscreen) {
        document.body.requestFullscreen();
    } else if (document.body.webkitRequestFullscreen) { /* Safari */
        document.body.webkitRequestFullscreen();
    } else if (document.body.msRequestFullscreen) { /* IE11 */
        document.body.msRequestFullscreen();
    }
}

/* Close fullscreen */
function closeFullscreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
    }
}

export default function useDevSupport(actualUI) {

    const [isFullScreen, setFullScreen] = React.useState(false);
    const [scannedText, setScannedText] = React.useState("");
    const [accessToken, setAccessToken] = React.useState("");
    const [mockAPIs, setMockAPIs] = React.useState(AppDataStore.get(APP_DATA_KEYS.APP_MOCK_NETWORK));
    const [appRegion, setAppRegion] = React.useState(AppDataStore.get(APP_DATA_KEYS.APP_REGION));
    const [appLocale, setAppLocale] = React.useState(AppDataStore.get(APP_DATA_KEYS.USER_LOCALE));
    const [appEnv, setAppEnv] = React.useState(AppDataStore.get(APP_DATA_KEYS.APP_ENV));
    const [action, setAction] = React.useState(null);
    const [features, setFeatures] = React.useState(DataHandler.userFeatures);

    const onChangeMockAPIs = (value) => {
        setMockAPIs(value);
        if (value) {
            AppDataStore.save(APP_DATA_KEYS.APP_MOCK_NETWORK, true);
        } else {
            AppDataStore.remove(APP_DATA_KEYS.APP_MOCK_NETWORK);
        }
    }

    const changeAppLocale = (value) => {
        setAppLocale(value);
        AppDataStore.save(APP_DATA_KEYS.USER_LOCALE, value);
    }

    const changeAppEnv = (value) => {
        setAppEnv(value);
        AppDataStore.save(APP_DATA_KEYS.APP_ENV, value);
    }

    const changeAppRegion = (value) => {
        setAppRegion(value);
        AppDataStore.save(APP_DATA_KEYS.APP_REGION, value);
    }

    const [toasts, setToasts] = React.useState([]);
    const onCloseToast = id => setToasts(toasts.filter(t => t.id !== id));
    const showToast = (message) => setToasts(toasts.concat({
        id: `${new Date().getTime()}`,
        timeout: TOASTER_TIMEOUT,
        message: message
    }));

    const onKeyDown = (event) => {
        if (event.keyCode === 13) {
            onScannedTextEntered();
        }
    }

    // sending scan event
    const onScannedTextEntered = () => {
        if (!scannedText) {
            return;
        }
        sendScanEventForText(scannedText);
        showToast(`Scanned event sent with value: ${scannedText}`);
        setScannedText("");
    }

    // sending auth event
    const onAccessTokenEntered = () => {
        window.dispatchEvent(new CustomEvent(APP_DATA_KEYS.ACCESS_TOKEN, {
            detail: {accessToken: accessToken},
            bubbles: true,
            cancelable: false
        }));
        showToast(`Auth Token event sent with value: ${accessToken}`);
    }

    if (isMobile()) {
        return actualUI;
    }

    return <Row spacingInset={"medium"} alignmentHorizontal={"center"}>
        <Column className={"smartphone content"}>
            {actualUI}
        </Column>
        <Toaster alignmentHorizontal={"right"} toasts={toasts} onCloseToast={onCloseToast}>
            {toast => (
                <Alert toast={true} onClose={toast.onClose}>
                    {toast.message}
                </Alert>
            )}
        </Toaster>
        <Column>
            <Row>
                <Column alignmentHorizontal={"center"} spacingInset={"medium"} spacing={"none"}>
                    <Button type={action === "start" ? "primary" : "secondary"} label={"start afresh"} onClick={() => {
                        if (action === "start") {
                            sessionStorage.clear();
                            window.location.href = "/";
                        } else {
                            setAction("start");
                            setTimeout(() => setAction(null), 1000);
                        }
                    }}>&nbsp;<Icon tokens={syncTokens}/>&nbsp;</Button>
                    Start Afresh
                    <Box spacingInset={"medium"}/>
                    <Button type={action === "reload" ? "primary" : "secondary"} label={"reload the page"}
                            onClick={() => {
                                if (action === "reload") {
                                    window.location.reload();
                                } else {
                                    setAction("reload");
                                    setTimeout(() => setAction(null), 1000);
                                }
                            }}>&nbsp;<Icon tokens={refreshTokens}/>&nbsp;</Button>
                    Refresh Page
                    <Box spacingInset={"medium"}/>
                    <Button label={"go or exit fullscreen"} onClick={() => {
                        if (!isFullScreen && document.fullscreenEnabled) {
                            openFullscreen();
                        }
                        if (isFullScreen) {
                            closeFullscreen();
                        }
                        setFullScreen(!isFullScreen);
                    }}>&nbsp;<Icon tokens={expandTokens}/>&nbsp;</Button>
                    Toggle Fullscreen
                    <Box spacingInset={"medium"}/>
                    <Button label={"go back"} onClick={() => {
                        window.dispatchEvent(new Event("BACK_PRESSED"));
                    }}>&nbsp;<Icon tokens={undoTokens}/>&nbsp;</Button>
                    Go Back
                </Column>
                <Column alignmentHorizontal={"center"} spacingInset={"medium"}>
                    <Row>
                        <Input placeholder={"Scanned Text"} id={"scannedText"} type="text" autoFill={false}
                               size="medium" value={scannedText} onChange={setScannedText} onKeyDown={onKeyDown}/>
                        <Button label={"scan"} onClick={onScannedTextEntered}>&nbsp;<Icon tokens={cameraTokens}/>&nbsp;
                        </Button>
                    </Row>
                    <Row>
                        <Input placeholder={"Access Token"} id={"accessToken"} type="text" autoFill={false}
                               size="medium" value={accessToken} onChange={setAccessToken}/>
                        <Button label={"scan"} onClick={onAccessTokenEntered}>&nbsp;<Icon tokens={userTokens}/>&nbsp;
                        </Button>
                    </Row>
                    <Divider/>
                    <Text>Change App Environment and Region</Text>
                    <Row>
                        <Select value={appEnv} onChange={changeAppEnv}
                                placeholder="ENV" width={130}>
                            {ENVS.map(env => <SelectOption key={env} value={env} label={env}/>)}
                        </Select>
                        <Select value={appRegion} onChange={changeAppRegion}
                                placeholder="REGION" width={130}>
                            {REGIONS.map(region => <SelectOption key={region} value={region} label={region}/>)}
                        </Select>
                    </Row>
                    <Divider/>
                    <Row>
                        <Text>Locale :</Text>
                        <Select value={appLocale} onChange={changeAppLocale}
                                placeholder="Locale" width={200}>
                            {Object.keys(LOCALE_MAP).map(locale => <SelectOption key={locale} value={locale}
                                                                                 label={LOCALE_MAP[locale]}/>)}
                        </Select>
                    </Row>
                    <Divider/>
                    <Row>
                        <Text>Features :</Text>
                        <Select
                            value={features} placeholder="Select Features to enable..." width={300}
                            onChange={(selected) => {
                                DataHandler.setUserFeatures(selected);
                                setFeatures(selected);
                            }} >
                            {Object.values(FeatureManager.Features).map((feature) => <SelectOption value={feature}
                                                                                                   label={feature} />)}
                        </Select>
                    </Row>
                    <Divider/>
                    <Row>
                        <Checkbox size={"large"} checked={!!mockAPIs} onChange={onChangeMockAPIs}>
                            Enable mock response for APIs
                        </Checkbox>
                    </Row>
                </Column>
            </Row>
        </Column>
    </Row>;
}
