import {Logger, PlayerHelper} from "@amzn/dolphin-web-framework";
import ProgramType from "../../models/enums/program-type";
import {ApplicationActions, Operations} from "../action/ApplicationActions";
import {DirectedPickingErrorCode} from "../../models/enums/directed-picking-errorCode";
import {ROUTE_PATH} from "../../routing";



export const PlayerReducer = (state, action) => {
    if (action.type === ApplicationActions.UPDATE_STATE) {
        if (action.data.operation === Operations.UPDATE_SCOPE) {
            return {
                ...state,
                scope: ProgramType.SSD
            };
        } else if (action.data.operation === Operations.UPDATE_SCOPE_AMZL) {
            return {
                ...state,
                scope: ProgramType.AMZL
            };
        } else if (action.data.operation === Operations.UPDATE_CART_SCAN_PROGRESS) {
            return {
                ...state,
                cartScanProgress: action.data.value
            };
        } else if (action.data.operation === Operations.UPDATE_BACK_SELECT_STATE && !action.data.value) {
            return {
                ...state,
                isBackSelect: true
            };
        }
    }

    try {
        if (window && window.DolphinPlayer) {
            if (state.scope === ProgramType.SSD) {
                let event, nextState;
                switch (action.type) {
                    case ApplicationActions.TASK_SELECTION: {
                        if (action.data.operation === Operations.STAGE_ROUTE) {
                            event = new window.DolphinPlayer.DrsStage.TaskSelectedEvent(window.DolphinPlayer.DrsStage.StageTask.STAGE_ROUTE);
                            nextState = new window.DolphinPlayer.DrsStage.StagingOverviewState();

                        } else if (action.data.operation === Operations.ROUTE_CODE_ENTRY) {
                            event = new window.DolphinPlayer.DrsStage.TaskSelectedEvent(window.DolphinPlayer.DrsStage.StageTask.ENTER_ROUTE_CODE);
                            nextState = new window.DolphinPlayer.DrsStage.RouteCodeEntryState(false);

                        } else if (action.data.operation === DirectedPickingErrorCode.NO_ASSIGNABLE_PICKLIST) {
                            event = new window.DolphinPlayer.DrsStage.TaskSelectedEvent(window.DolphinPlayer.DrsStage.StageTask.STAGE_ROUTE);
                            nextState = new window.DolphinPlayer.DrsStage.NoRouteAvailableState();
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.ENTER_ROUTE_CODE: {
                        if (action.data.operation === Operations.VALID_ROUTE_CODE_ENTERED) {
                            event = new window.DolphinPlayer.DrsStage.BarcodeScannedEvent(true);
                            nextState = new window.DolphinPlayer.DrsStage.StagingOverviewState();

                        } else if (action.data.operation === DirectedPickingErrorCode.ASSOCIATE_ALREADY_ASSIGNED) {
                            event = new window.DolphinPlayer.DrsStage.BarcodeScannedEvent(false);
                            nextState = new window.DolphinPlayer.DrsStage.RouteCodeEntryState(true,
                                window.DolphinPlayer.DrsStage.RouteCodeEntryErrorType.ROUTE_ALREADY_ASSIGNED);

                        } else if (action.data.operation === DirectedPickingErrorCode.PICKLIST_ALREADY_ASSIGNED) {
                            event = new window.DolphinPlayer.DrsStage.BarcodeScannedEvent(false);
                            nextState = new window.DolphinPlayer.DrsStage.RouteCodeEntryState(true,
                                window.DolphinPlayer.DrsStage.RouteCodeEntryErrorType.ROUTE_ASSIGNED_TO);

                        } else if (action.data.operation === DirectedPickingErrorCode.INVALID_PICKLIST_ID) {
                            event = new window.DolphinPlayer.DrsStage.BarcodeScannedEvent(false);
                            nextState = new window.DolphinPlayer.DrsStage.RouteCodeEntryState(true,
                                window.DolphinPlayer.DrsStage.RouteCodeEntryErrorType.INVALID_ROUTE_CODE);

                        } else if (action.data.operation === DirectedPickingErrorCode.PICKLIST_COMPLETED) {
                            event = new window.DolphinPlayer.DrsStage.BarcodeScannedEvent(false);
                            nextState = new window.DolphinPlayer.DrsStage.RouteCodeEntryState(true,
                                window.DolphinPlayer.DrsStage.RouteCodeEntryErrorType.ROUTE_ALREADY_STAGED);
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.CART_SCAN: {
                        let isFirstCart = false;
                        if (state.cartScanProgress === 0) {
                            isFirstCart = true;
                        }
                        if (action.data.operation === Operations.VALID_CART_SCAN) {
                            event = new window.DolphinPlayer.DrsStage.BarcodeScannedEvent(true);
                            nextState = new window.DolphinPlayer.DrsStage.LocationScanState(false);

                        } else if (action.data.operation === Operations.MISSING_CART) {
                            event = new window.DolphinPlayer.DrsStage.BarcodeScannedEvent(false);
                            nextState = new window.DolphinPlayer.DrsStage.CartScanState(isFirstCart, true, window.DolphinPlayer.DrsStage.CartScanErrorType.MISSING_CART);

                        } else if (action.data.operation === Operations.CART_ALREADY_STAGED) {
                            event = new window.DolphinPlayer.DrsStage.BarcodeScannedEvent(false);
                            nextState = new window.DolphinPlayer.DrsStage.CartScanState(isFirstCart, true, window.DolphinPlayer.DrsStage.CartScanErrorType.CART_ALREADY_STAGED);

                        } else if (action.data.operation === Operations.INCORRECT_CART_SCAN) {
                            event = new window.DolphinPlayer.DrsStage.BarcodeScannedEvent(false);
                            nextState = new window.DolphinPlayer.DrsStage.CartScanState(isFirstCart, true, window.DolphinPlayer.DrsStage.CartScanErrorType.INCORRECT_CART);

                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.LOCATION_SCAN: {
                        if (action.data.operation === Operations.STAGING_IN_PROGRESS) {
                            event = new window.DolphinPlayer.DrsStage.BarcodeScannedEvent(true);
                            nextState = new window.DolphinPlayer.DrsStage.CartScanState(false, false);

                        } else if (action.data.operation === Operations.INVALID_LOCATION_SCAN) {
                            event = new window.DolphinPlayer.DrsStage.BarcodeScannedEvent(false);
                            nextState = new window.DolphinPlayer.DrsStage.LocationScanState(true);

                        } else if (action.data.operation === Operations.STAGING_STATUS_COMPLETE) {
                            event = new window.DolphinPlayer.DrsStage.BarcodeScannedEvent(true);
                            nextState = new window.DolphinPlayer.DrsStage.StagingCompleteState(window.DolphinPlayer.DrsStage.StagingCompleteStatus.COMPLETE);

                        } else if (action.data.operation === Operations.STAGING_STATUS_PARTIALLY_COMPLETE) {
                            event = new window.DolphinPlayer.DrsStage.BarcodeScannedEvent(true);
                            nextState = new window.DolphinPlayer.DrsStage.StagingCompleteState(window.DolphinPlayer.DrsStage.StagingCompleteStatus.PARTIALLY_COMPLETE);

                        } else if (action.data.operation === Operations.NO_CART_STAGED) {
                            event = new window.DolphinPlayer.DrsStage.BarcodeScannedEvent(true);
                            nextState = new window.DolphinPlayer.DrsStage.StagingCompleteState(window.DolphinPlayer.DrsStage.StagingCompleteStatus.NO_CART_STAGED);
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            stagingStatus: action.data.operation
                        };
                    }
                    case ApplicationActions.STAGING_COMPLETE_OPTION_SELECTED: {
                        //Using the task select state as next state after user selects end staging or stage another route option
                        if (action.data.operation === Operations.STAGE_ANOTHER_ROUTE) {
                            event = new window.DolphinPlayer.DrsStage.StagingCompleteOptionSelectedEvent(window.DolphinPlayer.DrsStage.StagingCompleteOption.STAGE_ANOTHER_ROUTE);
                            nextState = new window.DolphinPlayer.DrsStage.TaskSelectState();

                        } else if (action.data.operation === Operations.END_STAGING) {
                            event = new window.DolphinPlayer.DrsStage.StagingCompleteOptionSelectedEvent(window.DolphinPlayer.DrsStage.StagingCompleteOption.END_STAGING);
                            nextState = new window.DolphinPlayer.DrsStage.TaskSelectState();
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.CART_REPLENISHMENT: {
                        event = new window.DolphinPlayer.DrsStage.ActionAcknowledgedEvent();
                        nextState = new window.DolphinPlayer.DrsStage.CartReplenishmentState();
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.REACHED_CART_LOCATION_OPTION_SELECTED: {
                        event = new window.DolphinPlayer.DrsStage.ActionAcknowledgedEvent();
                        nextState = new window.DolphinPlayer.DrsStage.CartScanState(true, false);
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.OPEN_HELP_OPTION: {
                        event = new window.DolphinPlayer.DrsStage.HelpCenterOpenedEvent();
                        nextState = new window.DolphinPlayer.DrsStage.HelpCenterState();
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.HELP_CENTRE_OPTION_SELECTED: {
                        if (action.data.operation === Operations.MISSING_CART) {
                            event = new window.DolphinPlayer.DrsStage.HelpCenterOptionSelectedEvent(window.DolphinPlayer.DrsStage.HelpCenterOption.CART_MISSING);
                            nextState = new window.DolphinPlayer.DrsStage.MissingCartConfirmState();
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.ON_BACK_SELECT: {
                        if (state.isBackSelect) {
                            event = new window.DolphinPlayer.DrsStage.BackActionSelectedEvent();
                            switch (action.data.operation) {
                                case ROUTE_PATH.PRE_DIRECTED_STAGING:
                                case ROUTE_PATH.ENTRY:
                                    nextState = new window.DolphinPlayer.DrsStage.TaskSelectState();
                                    break;
                                case ROUTE_PATH.ROUTE_INPUT:
                                    nextState = new window.DolphinPlayer.DrsStage.RouteCodeEntryState(false);
                                    break;
                                case ROUTE_PATH.PRE_STAGE_CONFIRMATION:
                                    nextState = new window.DolphinPlayer.DrsStage.StagingOverviewState();
                                    break;
                                case ROUTE_PATH.STOW_REPLENISHMENT:
                                    nextState = new window.DolphinPlayer.DrsStage.CartReplenishmentState();
                                    break;
                                case ROUTE_PATH.STAGING_ELEMENT:
                                    let isFirstCart = false;
                                    if (state.cartScanProgress === 0) {
                                        isFirstCart = true;
                                    }
                                    nextState = new window.DolphinPlayer.DrsStage.CartScanState(isFirstCart, false);
                                    break;
                                case ROUTE_PATH.SCAN_STAGING_AREA:
                                    nextState = new window.DolphinPlayer.DrsStage.LocationScanState(false);
                                    break;
                                case ROUTE_PATH.STAGING_COMPLETE:
                                    if (state.stagingStatus === Operations.STAGING_STATUS_COMPLETE) {
                                        nextState = new window.DolphinPlayer.DrsStage.StagingCompleteState(window.DolphinPlayer.DrsStage.StagingCompleteStatus.COMPLETE);
                                    } else if (state.stagingStatus === Operations.STAGING_STATUS_PARTIALLY_COMPLETE) {
                                        nextState = new window.DolphinPlayer.DrsStage.StagingCompleteState(window.DolphinPlayer.DrsStage.StagingCompleteStatus.PARTIALLY_COMPLETE);
                                    } else if (state.stagingStatus === Operations.NO_CART_STAGED) {
                                        nextState = new window.DolphinPlayer.DrsStage.StagingCompleteState(window.DolphinPlayer.DrsStage.StagingCompleteStatus.NO_CART_STAGED);
                                    }
                                    break;
                                default:
                                    return {
                                        ...state,
                                        isBackSelect: false
                                    };
                            }
                            PlayerHelper.sendMessage(event, nextState);
                        }
                        return {
                            ...state,
                            isBackSelect: false
                        };
                    }
                    case ApplicationActions.ON_EXIT_MODULE:
                        event = new window.DolphinPlayer.DrsStage.BackActionSelectedEvent();
                        nextState = new window.DolphinPlayer.DrsStage.TaskSelectState();
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    case ApplicationActions.ON_MISSING_CART_CANCEL_OPTION:
                        let isFirstCart = false;
                        if (state.cartScanProgress === 0) {
                            isFirstCart = true;
                        }
                        event = new window.DolphinPlayer.DrsStage.BackActionSelectedEvent();
                        nextState = new window.DolphinPlayer.DrsStage.CartScanState(isFirstCart, false);
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    case ApplicationActions.ON_BACK_DROP_OPEN:
                        event = new window.DolphinPlayer.DrsStage.BackActionSelectedEvent();
                        nextState = new window.DolphinPlayer.DrsStage.BackActionConfirmState();
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    default:
                        return state;
                }
            } else if (state.scope === ProgramType.AMZL) {
                let event, nextState;
                switch (action.type) {
                    case ApplicationActions.LOCATION_SCAN: {
                        if (action.data.operation === Operations.VALID_LOCATION_SCAN) {
                            event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(true);
                            if (action.data.type) {
                                nextState = new window.DolphinPlayer.Pick.CartScanState(window.DolphinPlayer.Pick.CartScanContainerType.BAG, false);
                            } else {
                                nextState = new window.DolphinPlayer.Pick.PickCompleteState();
                            }

                        } else if (action.data.operation === Operations.INVALID_LOCATION_SCAN) {
                            event = new window.DolphinPlayer.Pick.BarcodeScannedEvent(false);
                            nextState = new window.DolphinPlayer.Pick.LocationScanState(true);

                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            stagingStatus: action.data.operation
                        };
                    }
                    case ApplicationActions.STAGING_LOCATION_FULL_CONFIRM: {
                        if(action.data.operation === Operations.ACTION_ACKNOWLEDGED) {
                            event = new window.DolphinPlayer.Pick.StagingLocationFullConfirmState(true);
                            nextState = new window.DolphinPlayer.Pick.LocationScanState(false);
                        } else if(action.data.operation === Operations.ACTION_DECLINED) {
                            event = new window.DolphinPlayer.Pick.StagingLocationFullConfirmState(false);
                            nextState = new window.DolphinPlayer.Pick.LocationScanState(false);
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.OPEN_HELP_OPTION: {
                        event = new window.DolphinPlayer.Pick.HelpCenterOpenedEvent();
                        nextState = new window.DolphinPlayer.Pick.HelpCenterState(window.DolphinPlayer.Pick.HelpCenterContext.LOCATION_SCAN);
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.HELP_CENTRE_OPTION_SELECTED: {
                        if (action.data.operation === Operations.STAGING_FULL) {
                            event = new window.DolphinPlayer.Pick.HelpCenterOptionSelectedEvent(window.DolphinPlayer.Pick.HelpCenterOption.STAGING_FULL);
                            nextState = new window.DolphinPlayer.Pick.StagingLocationFullConfirmState();
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    default:
                        return state;
                }
            }
        } else {
            return {
                ...state,
                isInitialized: false,
            };
        }
        return state;
    } catch (error) {
        Logger.log.info("Player app message exception" + error);
        return state;
    }
}