export const APP_DATA_KEYS = {
    // Stage data keys
    CURRENT_CART: "CURRENT_CART",
    WORKFLOW_STAGE: "WORKFLOW_STAGE",
    WORKFLOW_STATE: "WORKFLOW_STATE",
    PICKING_DETAILS: "PICKING_DETAILS",
    LAST_RESOLUTION: "LAST_RESOLUTION",
    CONTAINER_TASK: "CONTAINER_TASK",
    SCANNED_CONTENT: "SCANNED_CONTENT",
    INIT_TIME: "INIT_TIME",
    ELAPSED_TIME: "ELAPSED_TIME",

    // Printer data keys
    TRY_PRINTER_CONNECT: "TRY_PRINTER_CONNECT",

    // App data keys
    ACCESS_TOKEN: "ACCESS_TOKEN",
    APP_REGION: "APP_REGION",
    APP_ENV: "APP_ENV",
    APP_UPDATE_AVAILABLE: "APP_UPDATE_AVAILABLE",
    APP_MOCK_NETWORK: "APP_MOCK_NETWORK",
    STAGING_LOCATION_OVERFLOW: "STAGING_LOCATION_OVERFLOW",
    MANUAL_OVERRIDE: "MANUAL_OVERRIDE"

};

class AppData {

    save(key, value) {
        if (value === null || value === undefined) {
            sessionStorage.removeItem(key);
        } else {
            sessionStorage.setItem(key, JSON.stringify(value));
        }
    }

    remove(key) {
        sessionStorage.removeItem(key);
    }

    get(key) {
        const value = sessionStorage.getItem(key);
        try {
            return JSON.parse(value);
        } catch (e) {
            console.log(`Error while parsing data for ${key} with value ${value}`, e);
            return null;
        }
    }

    setWorkFlowInExecution(state) {
        this.save(APP_DATA_KEYS.WORKFLOW_STATE, state);
    }

    getWorkflowStage() {
        return this.get(APP_DATA_KEYS.WORKFLOW_STAGE);
    }

    setWorkflowStage(stage) {
        if (stage === null) {
            this.save(APP_DATA_KEYS.WORKFLOW_STAGE, "");
        } else {
            this.save(APP_DATA_KEYS.WORKFLOW_STAGE, stage);
        }
    }

    setPickListDetails(picklistDetails) {
        this.save(APP_DATA_KEYS.PICKING_DETAILS, picklistDetails);
    }

    getPickListDetail() {
        return this.get(APP_DATA_KEYS.PICKING_DETAILS);
    }

    setScannedContent(scannedContent) {
        this.save(APP_DATA_KEYS.SCANNED_CONTENT, scannedContent);
    }

    getScannedContent() {
        return this.get(APP_DATA_KEYS.SCANNED_CONTENT);
    }

    setLastResolution(lastResolution) {
        this.save(APP_DATA_KEYS.LAST_RESOLUTION, lastResolution);
    }

    getLastResolution() {
        return this.get(APP_DATA_KEYS.LAST_RESOLUTION)
    }

    setWorkflowInExecution(state) {
        this.save(APP_DATA_KEYS.WORKFLOW_STATE, state);
    }

    isWorkflowExecuting() {
        return this.get(APP_DATA_KEYS.WORKFLOW_STATE);
    }

    updateElapsedTime(time) {
        this.save(APP_DATA_KEYS.ELAPSED_TIME, time);
    }

    getElapsedTime() {
        return this.get(APP_DATA_KEYS.ELAPSED_TIME)
    }

    updateInitTime(time) {
        this.save(APP_DATA_KEYS.INIT_TIME, time);
    }

    getInitTime() {
        return this.get(APP_DATA_KEYS.INIT_TIME);
    }

    setCurrentCart(cart) {
        this.save(APP_DATA_KEYS.CURRENT_CART, cart);
    }

    getCurrentCart() {
        return this.get(APP_DATA_KEYS.CURRENT_CART);
    }

    setFeatures(featureMap) {
        this.save(APP_DATA_KEYS.FEATURES, featureMap);
    }

    getContainerTask() {
        return this.get(APP_DATA_KEYS.CONTAINER_TASK);
    }

    setContainerTask(task) {
        this.save(APP_DATA_KEYS.CONTAINER_TASK, task);
    }

    isStagingLocationOverflow() {
        return this.get(APP_DATA_KEYS.STAGING_LOCATION_OVERFLOW);
    }

    setIsStagingLocationOverflow(overflow) {
        this.save(APP_DATA_KEYS.STAGING_LOCATION_OVERFLOW, overflow);
    }

    isManualOverride() {
        return this.get(APP_DATA_KEYS.MANUAL_OVERRIDE);
    }

    setIsManualOverride(manualOverride) {
        this.save(APP_DATA_KEYS.MANUAL_OVERRIDE, manualOverride);
    }


}

const AppDataStore = new AppData();

export default AppDataStore;