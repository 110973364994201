import React, {createContext, useReducer} from "react";
import {picklistDetailReducer} from "../reducer/picklist-detail-reducer"
import AppDataStore from "../components/app-data";

export const PickListDetailContext = createContext();

const PickListDetailContextProvider = (props) => {

    let initState = {
        picklistId : "",
        activeCart : "",
        routeId: "",
        routeCode : "",
        pickingAisle : [],
        activeStage : "",
        chutes : [],
        routeLength : "",
        programType : "",
        pickStatus : "",
        executionMode : "",
        picklistDetail : "",
        currentCart : "",
        containerTask : null,
        routeNodeId : "",
        resourceNodeId : "",
        scannedContent: "",
        lastResolution: null
    };

    const [statePickListDetail, dispatchPickListDetail] = useReducer(picklistDetailReducer, initState);

    if(!statePickListDetail.picklistDetail) {
        statePickListDetail.picklistDetail = AppDataStore.getPickListDetail();
    }

    if(!statePickListDetail.currentCart) {
        statePickListDetail.currentCart = AppDataStore.getCurrentCart();
    }

    return (
        <PickListDetailContext.Provider value={{statePickListDetail, dispatchPickListDetail}}>
            {props.children}
        </PickListDetailContext.Provider>
    )

}

export default PickListDetailContextProvider;