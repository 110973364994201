import {Logger} from "@amzn/dolphin-web-framework";

const SCANNED = "SCANNED";
const OK_KEY_CODES = [13]; // we may need to add other keyCodes as well

let currOnScanListener = undefined;
let scannedText = "";

function getContainerId(data) {
    try {
        let jsonObject = JSON.parse(data);
        if (jsonObject && jsonObject["CONTAINER_ID"]) {
            jsonObject = JSON.parse(jsonObject["CONTAINER_ID"]);
            if (jsonObject && jsonObject["PACKAGE_KEY"]) {
                return jsonObject["PACKAGE_KEY"];
            }
        }
    } catch (e) {
        return data;
    }
    return data;
}

export function sendScanEventForText(scannedText) {
    window.dispatchEvent(new CustomEvent(SCANNED, {
        detail: {scannedText}
    }));
}

/**
 * The keyDown listener assumes the input provider for basic operations where a stream of events are fired
 * Also, the ENTER key event which is 13 for keyCode is fired on successful
 * @param e
 */
function onKeyDown(e) {
    // if activeElement is a input box or we don't have a listener, do nothing and reset
    if (document.activeElement.tagName.toLowerCase() === "input" || !currOnScanListener) {
        scannedText = "";
        return;
    }
    if (e.keyCode === 8) {
        if (scannedText) {
            scannedText = scannedText.substr(0, scannedText.length - 1);
        }
    } else if (OK_KEY_CODES.indexOf(e.keyCode) >= 0 && scannedText) {
        sendScanEventForText(scannedText);
        scannedText = "";
    } else if (e.key.length === 1) {
        scannedText += e.key;
    }
}

function onScannedEvent(e) {
    const scanned = getContainerId(e.detail.scannedText);
    Logger.log.info("scan event", {
        scannedOnPage: window.location.hash,
        scannedText: scanned
    });
    scanned && currOnScanListener && currOnScanListener.call(undefined, scanned);
}

window.addEventListener('keydown', onKeyDown);
window.addEventListener(SCANNED, onScannedEvent);
