import React from "react";
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import AppLoader from "./components/app-loader";
import {FeatureManager, PUMAMetricHelper} from "@amzn/dolphin-web-framework";
import {isStagingServiceEnabled} from "./components/utils/feature-utility";

const PreDirectedStaging = React.lazy(() => import("./components/pre/pre-directed-staging"));
const RouteInput = React.lazy(() => import("./components/pre/route-input"));
const PreStageConfirmation = React.lazy(() => import("./components/pre/pre-stage-confirmation"));
const StowReplenishment = React.lazy(() => import("./components/pre/stow-replenishment"));
const ScanContainer = React.lazy(() => import("./components/execute/stage.container/scan-container"));
const ScanContainerV2 = React.lazy(() => import("./components/execute/stage.container/scan-container-v2"));
const StageContainer = React.lazy(() => import("./components/execute/stage.container/stage-container"));
const StageContainerV2 = React.lazy(() => import("./components/execute/stage.container/stage-container-v2"));
const JumpToInstructionConfirmation = React.lazy(() => import("./components/execute/stage.container/jump-to-instruction-confirmation"));
const ConfirmAssignedContainer = React.lazy(() => import("./components/execute/stage.container/confirm-assigned-container"));
const StagingElement = React.lazy(() => import("./components/execute/staging-element"));
const ScanStagingArea = React.lazy(() => import("./components/postExecution/scan-staging-area"));
const StagingComplete = React.lazy(() => import("./components/postExecution/staging-complete"));
const PickingIssue = React.lazy(() => import("./components/postExecution/picking-issue"));
const PostPrintScanContainer = React.lazy(() => import("./components/execute/stage.container/post-print-scan-container"));
const PrinterConnection = React.lazy(() => import("./components/execute/stage.container/printer-connection"));
const DetachContainerConfirmation = React.lazy(() => import("./components/execute/stage.container/detach-container-confirmation"));

export const ROUTE_PATH = {
    ENTRY: "/",
    ROUTE_INPUT: "/routeInput",
    PRE_STAGE_CONFIRMATION: "/preStageConfirmation",
    STOW_REPLENISHMENT: "/stowReplenishment",
    SCAN_CONTAINER: "/scanContainer",
    STAGE_CONTAINER: "/stageContainer",
    JUMP_TO_INSTRUCTION_CONFIRMATION: "/jumpToInstructionConfirmation",
    CONFIRM_ASSIGNED_CONTAINER: "/confirmAssignedContainer",
    STAGING_ELEMENT: "/stagingElement",
    SCAN_STAGING_AREA: "/scanStagingArea",
    STAGING_COMPLETE: "/stagingComplete",
    PICKING_ISSUE: "/pickingIssue",
    POST_PRINT_SCAN_CONTAINER: "/postPrintScanContainer",
    PRINTER_CONNECTION: "/printerConnection",
    PRE_DIRECTED_STAGING: "/preDirectedStaging",
    DETACH_CONTAINER_CONFIRMATION : "/detachContainerConfirmation"
};

function Routing(props) {

    props.history.listen((args) => {
        const oldPathName = props.location.pathname;
        const newPathName = args.pathname;
        if(oldPathName !== newPathName)  {
            PUMAMetricHelper.publishCountToDolphinCSM(newPathName);
        }
    });

    return (
        <React.Suspense fallback={<AppLoader/>}>
            <Switch>
                <Route exact path={ROUTE_PATH.ENTRY} render={() => {
                    return (
                        (FeatureManager.isFeatureEnabled(FeatureManager.Features.DECOUPLED_PICK_STAGE) ||
                            (FeatureManager.isFeatureEnabled(FeatureManager.Features.HIDE_REQUEST_STAGE_LIST)
                                && !FeatureManager.isFeatureEnabled(FeatureManager.Features.ASSIGN_TO_STAGE)))
                            && !(FeatureManager.isFeatureEnabled(FeatureManager.Features.SPLIT_STAGING_RAMP_WORKFLOW_ENABLED) ||
                            FeatureManager.isFeatureEnabled(FeatureManager.Features.SPLIT_STAGING_AUTOLATOR_WORKFLOW_ENABLED))?
                            <Redirect to={ROUTE_PATH.SCAN_CONTAINER}/> :
                            <Redirect to={ROUTE_PATH.PRE_DIRECTED_STAGING}/>
                    )
                }}/>
                <Route exact path={ROUTE_PATH.PRE_DIRECTED_STAGING} component={PreDirectedStaging}/>
                <Route exact path={ROUTE_PATH.ROUTE_INPUT} component={RouteInput}/>
                <Route exact path={ROUTE_PATH.PRE_STAGE_CONFIRMATION} component={PreStageConfirmation}/>
                <Route exact path={ROUTE_PATH.STOW_REPLENISHMENT} component={StowReplenishment}/>
                {
                    !isStagingServiceEnabled() ?
                            <Route exact path={ROUTE_PATH.SCAN_CONTAINER} component={ScanContainer}/> :
                            <Route exact path={ROUTE_PATH.SCAN_CONTAINER} component={ScanContainerV2}/>
                }
                {
                    !isStagingServiceEnabled() ?
                        <Route exact path={ROUTE_PATH.STAGE_CONTAINER} component={StageContainer}/> :
                        <Route exact path={ROUTE_PATH.STAGE_CONTAINER} component={StageContainerV2}/>
                }
                <Route exact path={ROUTE_PATH.JUMP_TO_INSTRUCTION_CONFIRMATION}
                       component={JumpToInstructionConfirmation}/>
                <Route exact path={ROUTE_PATH.CONFIRM_ASSIGNED_CONTAINER} component={ConfirmAssignedContainer}/>
                <Route exact path={ROUTE_PATH.STAGING_ELEMENT} component={StagingElement}/>
                <Route exact path={ROUTE_PATH.SCAN_STAGING_AREA} component={ScanStagingArea}/>
                <Route exact path={ROUTE_PATH.STAGING_COMPLETE} component={StagingComplete}/>
                <Route exact path={ROUTE_PATH.PICKING_ISSUE} component={PickingIssue}/>
                <Route exact path={ROUTE_PATH.POST_PRINT_SCAN_CONTAINER} component={PostPrintScanContainer}/>
                <Route exact path={ROUTE_PATH.PRINTER_CONNECTION} component={PrinterConnection}/>
                <Route exact path={ROUTE_PATH.DETACH_CONTAINER_CONFIRMATION} component={DetachContainerConfirmation}/>
            </Switch>
        </React.Suspense>
    );
}

export default withRouter(Routing);
