const DirectedPickingErrorCode = {
    INTERNAL_ERROR: "INTERNAL_ERROR",
    ASSOCIATE_ALREADY_ASSIGNED: "ASSOCIATE_ALREADY_ASSIGNED",
    NO_ASSIGNABLE_PICKLIST: "NO_ASSIGNABLE_PICKLIST",
    PICKLIST_EMPTY_EXCEPTION: "PICKLIST_EMPTY_EXCEPTION",
    NO_OPTIMAL_PICKLIST: "NO_OPTIMAL_PICKLIST",
    PICKLIST_ALREADY_ASSIGNED: "PICKLIST_ALREADY_ASSIGNED",
    PICKLIST_DOES_NOT_EXIST: "PICKLIST_DOES_NOT_EXIST",
    CART_DOES_NOT_EXIST: "CART_DOES_NOT_EXIST",
    CART_INCORRECT: "CART_INCORRECT",
    PICKLIST_NOT_ASSIGNED_TO_ASSOCIATE: "PICKLIST_NOT_ASSIGNED_TO_ASSOCIATE",
    CART_ALREADY_ADDED: "CART_ALREADY_ADDED",
    CART_ALREADY_IN_USE: "CART_ALREADY_IN_USE",
    INVALID_STAGING_AREA_ID: "INVALID_STAGING_AREA_ID",
    RESOURCE_TYPE_INCORRECT: "RESOURCE_TYPE_INCORRECT",
    CART_ALREADY_STAGED: "CART_ALREADY_STAGED",
    PICKLIST_ITEM_DONT_MATCH: "PICKLIST_ITEM_DONT_MATCH",
    INVALID_MOVE: "INVALID_MOVE",
    INVALID_PICKLIST_ID: "INVALID_PICKLIST_ID",
    CONTAINER_DOES_NOT_EXIST: "CONTAINER_DOES_NOT_EXIST",
    CONTAINER_TASK_COMPLETED: "CONTAINER_TASK_COMPLETED",
    PROBLEM_SOLVE_BIN_INVALID: "PROBLEM_SOLVE_BIN_INVALID",
    PICKLIST_COMPLETED: "PICKLIST_COMPLETED",
    PICKLIST_DEPARTED: "PICKLIST_DEPARTED",
    PICKLIST_UNASSIGNED: "PICKLIST_UNASSIGNED",
    PICKLIST_ITEM_UNAVAILABLE: "PICKLIST_ITEM_UNAVAILABLE",
    INVALID_CART_STATUS: "INVALID_CART_STATUS",
    STAGING_LOCATION_UPDATED: "STAGING_LOCATION_UPDATED",
    PRE_STAGING_AREA_FULL: "PRE_STAGING_AREA_FULL",
    PRE_STAGE_AREA_NOT_CONFIGURED: "PRE_STAGE_AREA_NOT_CONFIGURED",
    CONTAINER_OPEN: "CONTAINER_OPEN",
    TRAILER_NOT_FOUND: "TRAILER_NOT_FOUND"
}

const DirectedPickingErrorCodeMap = {
    INTERNAL_ERROR: 'pick_internal_error',
    ASSOCIATE_ALREADY_ASSIGNED: 'associate_already_assigned',
    NO_ASSIGNABLE_PICKLIST: 'no_assignable_route_found',
    NO_OPTIMAL_PICKLIST: 'no_optimal_route_found',
    PICKLIST_ALREADY_ASSIGNED: 'picklist_assigned',
    PICKLIST_DOES_NOT_EXIST: 'route_invalid',
    CART_DOES_NOT_EXIST: 'invalid_cart_id',
    CART_INCORRECT: 'invalid_cart',
    PICKLIST_NOT_ASSIGNED_TO_ASSOCIATE: 'route_not_assigned_to_associate',
    CART_ALREADY_ADDED: 'cart_already_added_to_route',
    CART_ALREADY_IN_USE: 'cart_belong_to_route',
    INVALID_STAGING_AREA_ID: 'staging_area_not_valid',
    RESOURCE_TYPE_INCORRECT: 'staging_area_not_valid',
    CART_ALREADY_STAGED: 'cart_already_staged',
    PICKLIST_ITEM_DONT_MATCH: 'item_not_belong_to_picklist',
    INVALID_MOVE: 'cannot_move_item_to_cart',
    INVALID_PICKLIST_ID: 'invalid_route_code',
    CONTAINER_DOES_NOT_EXIST: 'wrong_item_scan',
    CONTAINER_TASK_COMPLETED: "cart_already_staged",
    PROBLEM_SOLVE_BIN_INVALID: 'incorrect_problem_solve_bin',
    PICKLIST_COMPLETED: 'route_picked',
    PICKLIST_DEPARTED: 'picklist_departed',
    PICKLIST_UNASSIGNED: 'picklist_not_assigned',
    PICKLIST_ITEM_UNAVAILABLE: 'picklist_item_unavailable',
    INVALID_CART_STATUS: 'invalid_cart_status',
    STAGING_LOCATION_UPDATED: 'stage_location_updated',
    PRE_STAGING_AREA_FULL: 'pre_staging_area_full',
    PRE_STAGE_AREA_NOT_CONFIGURED: 'psa_not_configured',
    CONTAINER_OPEN: 'stage_cart_open',
    TRAILER_NOT_FOUND: 'trailer_not_found',
    PICKLIST_EMPTY_EXCEPTION: 'picklist_empty_exception'
}

export {DirectedPickingErrorCode, DirectedPickingErrorCodeMap};