import React from "react";
import {PUMAMetricHelper, Logger, NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {ErrorView} from "@amzn/dolphin-uiweb-framework";

export default class AppError extends React.Component {

    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        Logger.log.fatal("ErrorBoundary", error, errorInfo);
        const metricName = "clientError";
        PUMAMetricHelper.publishCountToDolphinCSM(metricName);
    }

    render() {
        // if it error or it doesn't have any children
        if (this.state.hasError || !this.props.children) {
            return <ErrorView errorMessage={"stage.error"} title={"Stage"} onBackClicked={NativeMeshInteractor.exitModule}/>;
        }
        return this.props.children;
    }
}