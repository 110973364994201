import helpTokens from "@amzn/meridian-tokens/base/icon/help";
import React, {useContext, useEffect} from 'react';
import {HelpView, TopNavigationView, BackPressedView} from "@amzn/dolphin-uiweb-framework";
import Column from "@amzn/meridian/column";
import {injectIntl} from "react-intl";
import {AppContext} from "../context/app-context";
import {withRouter} from 'react-router-dom';
import helpImage from '../assets/images/help_coming_up.png';
import {clearBackPressedEventListener, setBackPressedEventListener} from "../bridge/back-button";
import {STRINGS} from "../il8n/strings";
import version from "../app-version";
import AppDataStore, {APP_DATA_KEYS} from "./app-data";
import Text from "@amzn/meridian/text";
import {Constants} from "@amzn/dolphin-web-framework";
import {ApplicationActions, Operations} from "../player/action/ApplicationActions";
import {PlayerContext} from "../player/context/PlayerContext";

const prodEnv = AppDataStore.get(APP_DATA_KEYS.APP_ENV) === Constants.Stage.PROD;

function AppTopNavigation(props) {

    const {appState, appActions} = useContext(AppContext);
    const {dispatchPlayer} = useContext(PlayerContext);

    /**
     * The following code is listening to the back press events
     * The code is supposed to close any views like help or backdrop view
     * in case of either are not visible we call onBackPressed function from the appState
     * the default behaviour is to go back in history
     */
    const onBackPressed = React.useCallback(() => {
        // TODO: handle backpress for all screens for the help options to be closed

        if (appState.helpOptions.open) {
            appActions.closeHelp();
        } else if (appState.backOptions.open) {
            appActions.closeBackDrop();
        } else if (appState.onBackPressed) {
            appState.onBackPressed.call();
        } else {
            dispatchPlayer({type: ApplicationActions.UPDATE_STATE, data: {operation: Operations.UPDATE_BACK_SELECT_STATE}});
            props.history.goBack();
        }
    }, [appState.onBackPressed, appState.backOptions, appState.helpOptions]);

    useEffect(() => {
        console.log("setBackPressedEventListener changes");
        setBackPressedEventListener(onBackPressed);
        dispatchPlayer({type: ApplicationActions.ON_BACK_SELECT, data: {operation: props.history.location.pathname}});
        return clearBackPressedEventListener;
    }, [appState.onBackPressed, appState.backOptions, appState.helpOptions]);

    return (
        <Column
            id={"app-header"}
            width={"100%"}
        >
            <TopNavigationView
                backgroundType={appState.headerType}
                backgroundColor={appState.headerBackgroundColor}
                title={appState.title}
                onMenuButtonClicked={onBackPressed}
                optionsMenu={[{label: appState.helpOptions.title, icon: helpTokens, onClick: appActions.openHelp}]}/>
            {appState.helpOptions.open &&
            <div style={{position: 'absolute', height: '100%', width: '100%'}}>
                <HelpView help_icon={helpImage} onClose={appActions.closeHelp} {...appState.helpOptions} />
                <div style={{zIndex: 40, padding: '10px', position: 'absolute', bottom: 0, right: 0}}>
                    {/* show version in non prod environments */}
                    <Text type={"h300"}>{!prodEnv && version}</Text>
                </div>
            </div>}
            {appState.backOptions.open &&
            <BackPressedView
                onClose={appActions.closeBackDrop}
                title={props.intl.formatMessage({id: STRINGS.back_button_pressed})}
                description={props.intl.formatMessage({id: STRINGS.back_button_notification_message})}
                primaryButtonTitle={props.intl.formatMessage({id: STRINGS.back_cancel_message})}
                secondaryButtonTitle={props.intl.formatMessage({id: STRINGS.back_confirm_message})}
                primaryButtonOnClick={appState.backOptions.primaryButtonOnClick}
                secondaryButtonOnClick={appState.backOptions.secondaryButtonOnClick}/>}
            {!appState.backOptions.open && appState.dialogOptions.open && !appState.backOptions.open &&
            <BackPressedView
                onClose={appState.dialogOptions.onClose}
                title={appState.dialogOptions.title}
                description={appState.dialogOptions.description}
                primaryButtonTitle={appState.dialogOptions.primaryButtonTitle}
                secondaryButtonTitle={appState.dialogOptions.secondaryButtonTitle}
                primaryButtonOnClick={appState.dialogOptions.primaryButtonOnClick}
                secondaryButtonOnClick={appState.dialogOptions.secondaryButtonOnClick}/>
            }
        </Column>
    );
}

export default injectIntl(withRouter(AppTopNavigation));