import React, {createContext, useReducer} from "react";
import {AppContextActions, AppContextReducer, DEFAULT_VALUE} from "../reducer/app-context-reducer";
import {ApplicationActions, Operations} from "../player/action/ApplicationActions";
import {PlayerContext} from "../player/context/PlayerContext";

export const AppContext = createContext(DEFAULT_VALUE);

const AppContextProvider = (props) => {
    const [appState, dispatchApp] = useReducer(AppContextReducer, DEFAULT_VALUE);
    const {dispatchPlayer} = React.useContext(PlayerContext);

    const setupHeader = (title, onBackPressed) => {
        dispatchApp({type: AppContextActions.SETUP_HEADER, value: {title, onBackPressed}});
    }

    const setupHelp = (enabled, options) => {
        dispatchApp({type: AppContextActions.SETUP_HELP, value: {enabled, options}});
    }

    const openHelp = () => {
        dispatchPlayer({type: ApplicationActions.OPEN_HELP_OPTION});
        dispatchApp({type: AppContextActions.OPEN_HELP});
    }

    const closeHelp = (isUpdateStateDisabled) => {
        dispatchPlayer({type: ApplicationActions.UPDATE_STATE, data: {operation: Operations.UPDATE_BACK_SELECT_STATE, value: isUpdateStateDisabled}});
        dispatchApp({type: AppContextActions.CLOSE_HELP});
    }

    const setHeaderBackground = (color) => {
        dispatchApp({type: AppContextActions.SET_HEADER_COLOR, value: color});
    }

    const setHeaderType = (type) => {
        dispatchApp({type: AppContextActions.SET_HEADER_TYPE, value: type});
    }

    const openBackDrop = (options) => {
        dispatchPlayer({type: ApplicationActions.ON_BACK_DROP_OPEN});
        dispatchApp({type: AppContextActions.OPEN_BACK_DROP, value: options});
    }

    const closeBackDrop = (isUpdateStateDisabled) => {
        dispatchPlayer({type: ApplicationActions.UPDATE_STATE, data: {operation: Operations.UPDATE_BACK_SELECT_STATE, value: isUpdateStateDisabled}});
        dispatchApp({type: AppContextActions.CLOSE_BACK_DROP});
    }


    const openDialog = (options) => {
        dispatchApp({type: AppContextActions.OPEN_DIALOG, value: options});
    }

    const closeDialog = () => {
        dispatchApp({type: AppContextActions.CLOSE_DIALOG});
    }

    return (
        <AppContext.Provider value={{
            appState, appActions: {
                setupHeader,
                setupHelp,
                openHelp,
                closeHelp,
                openBackDrop,
                closeBackDrop,
                setHeaderBackground,
                setHeaderType,
                openDialog,
                closeDialog
            }
        }}>
            {props.children}
        </AppContext.Provider>
    )
}

export default AppContextProvider;