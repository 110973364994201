export const WORKFLOW_STAGE = {
    SPS: "SPS",
    SES: "SES"
}
export const CONTEXT_TYPE = {
    SS_STEP_ONE: "SS_STEP_ONE",
    ON_DEMAND: "ON_DEMAND"
}
export const LocationType = {
    PRE_STAGE_AREA: "PRE-STAGE-AREA"
}
export const EMPTY_STRING = ""