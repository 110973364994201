export const STRINGS = {
    scan_print_barcode_desc: "scan_print_barcode_desc",
    scan_printer_barcode: "scan_printer_barcode",
    no_printer_connected: "no_printer_connected",
    picklist_empty_exception: "picklist_empty_exception",
    printer_connection_failed: "printer_connection_failed",
    no_route_available: "no_route_available",
    stage_location_updated: "stage_location_updated",
    route_has_issue: "route_has_issue",
    picking_can_not_be_complete: "picking_can_not_be_complete",
    picklist_not_assigned: "picklist_not_assigned",
    picklist_no_longer_assigned: "picklist_no_longer_assigned",
    route_staged: "route_staged",
    pick_internal_error: "pick_internal_error",
    incorrect_staging_location: "incorrect_staging_location",
    cart_already_attached: "cart_already_attached",
    btn_acknowledge_close_sector : "btn_acknowledge_close_sector",
    missing : "missing",
    missing_cart_confirmation : "missing_cart_confirmation",
    confirm_action : "confirm_action",
    cart : "cart",
    confirm : "confirm",
    btn_cancel : "btn_cancel",
    cart_missing_notification: "cart_missing_notification",
    staging_complete : "staging_complete",
    stage_more : "stage_more",
    end_staging : "end_staging",
    route_partially_staged : "route_partially_staged",
    route_partially_staged_desc : "route_partially_staged_desc",
    single_cart_completion_summary : "single_cart_completion_summary",
    carts_completion_summary : "carts_completion_summary",
    directed_staging_location : "directed_staging_location",
    move_staging_cart_title : "move_staging_cart_title",
    scan_stage_location : "scan_stage_location",
    scan_pre_stage_location : "scan_pre_stage_location",
    cart_staged_location : "cart_staged_location",
    stage_location : "stage_location",
    pre_stage_location : "pre_stage_location",
    scan_full_cart : "scan_full_cart",
    find_staging_cart : "find_staging_cart",
    find_stage_cart_and_scan : "find_stage_cart_and_scan",
    overflow_stage_location : "overflow_stage_location",
    confirm_cart_location_full_title : "confirm_cart_location_full_title",
    confirm_cart_location_full_subtitle :"confirm_cart_location_full_subtitle",
    location_full :"location_full",
    staging_location_full: "staging_location_full",
    staging_location_marked_full_success: "staging_location_marked_full_success",
    move_staging_cart_available_title : "move_staging_cart_available_title",
    move_staging_cart_overflow_title:"move_staging_cart_overflow_title",
    stg_container : "stg_container",
    stg_detach_container_guidance : "stg_detach_container_guidance",
    stg_detach_container_action : "stg_detach_container_action",
    stg_confirm_and_continue : "stg_confirm_and_continue",
    chute : "chute",
    zone : "zone",
    cart_already_staged : "cart_already_staged",
    incorrect_cart_scan : "incorrect_cart_scan",
    route_status : "route_status",
    move_cart_to_location : "move_cart_to_location",
    stage_scan_location : "stage_scan_location",
    location : "location",
    stage_container_label : "stage_container_label",
    staging_area : "staging_area",
    dock_door : "dock_door",
    cart_moved_to_area : "cart_moved_to_area",
    attach_print_label: "attach_print_label",
    scan_new_label: "scan_new_label",
    stage_label_print_success: "stage_label_print_success",
    stg_cart_not_ready: "stg_cart_not_ready",
    stage_printer_connected: "stage_printer_connected",
    stage_scan_container_to_stage : "stage_scan_container_to_stage",
    find_containers : "find_containers",
    stage_container_scan_success : "stage_container_scan_success",
    stg_container_not_ready : "stg_container_not_ready",
    stg_container_processed : "stg_container_processed",
    stg_container_already_assigned : "stg_container_already_assigned",
    stg_container_assigned_to : "stg_container_assigned_to",
    help_center : "help_center",
    missing_cart : "missing_cart",
    staging_help : "staging_help",
    reprint_label : "reprint_label",
    confirm_move_to_dock: "confirm_move_to_dock",
    depart_container_title: "depart_container_title",
    cancel_move_to_dock: "cancel_move_to_dock",
    depart_container: "depart_container",
    stage_station_pair : "stage_station_pair",
    stage_cart_id : "stage_cart_id",
    move_empty_cart_title : "move_empty_cart_title",
    move_empty_cart : "move_empty_cart",
    reached_cart_location : "reached_cart_location",
    assigned_route : "assigned_route",
    picking_aisles : "picking_aisles",
    cart_count : "cart_count",
    cart_number : "cart_number",
    chute_location : "chute_location",
    zone_location : "zone_location",
    pick_overview : "pick_overview",
    select_stage_task : "select_stage_task",
    get_pick_list : "get_pick_list",
    get_picklist_description : "get_picklist_description",
    enter_route_code : "enter_route_code",
    enter_route_code_description : "enter_route_code_description",
    stage_get_containers_stage : "stage_get_containers_stage",
    stage_get_containers_stage_desc : "stage_get_containers_stage_desc",
    stage_container_title : "stage_container_title",
    stage_container_desc : "stage_container_desc",
    stage_route : "stage_route",
    stage_route_description : "stage_route_description",
    enter_stage_route_code_description : "enter_stage_route_code_description",
    stg_container_not_available : "stg_container_not_available",
    get_route_code: "get_route_code",
    stage_label_print_error: "stage_label_print_error",
    route_not_staged: "route_not_staged",
    route_not_staged_desc: "route_not_staged_desc",
    back_button_pressed: "back_button_pressed",
    back_button_notification_message: "back_button_notification_message",
    back_cancel_message: "back_cancel_message",
    back_confirm_message: "back_confirm_message",
    stage_overview: "stage_overview",
    btn_continue: "btn_continue",
    btn_retry: "btn_retry",
    error_assign_container: "error_assign_container",
    retry_assign_container: "retry_assign_container",
    error_reopening_cart: "error_reopening_cart",
    GOT_IT: "got_it",
    TRAINING_BLOCKER_TITLE: "training_blocker_title",
    TRAINING_BLOCKER_DESC: "training_blocker_desc",
    ALERT_ERROR: "alert_error",
    ALERT_WARNING: "alert_warning",
    MISSING_PERMISSION:"missing_permission"
}