export const Modules = {
    PICK: 'PICK',
    STAGE: 'STAGE',
    STOW: 'STOW'
};

export const MODULE_NAME = 'DirectedStage';

//training status
export const TRAINING_STATUS = {
    EXPIRED: 'EXPIRED'
};

export const METRIC_FAILURE_TRUE = true;
export const METRIC_FAILURE_FALSE = false;